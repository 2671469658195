import React from "react"
import BrandHeader from "../../components/brand-headers/rockyBrandHeader"
import BrandBody from "../../components/brand-bodies/rockyBrandBody"
import Layout from "../../components/layout.js"
import Seo from "../../components/seo"

const RockyBootsPage = ({ pageContext, location }) => {
  const pageTitle = "Rocky Boots"

  return (
    <>
      <Layout>
        <Seo title={pageTitle} />

        <BrandHeader />
        <BrandBody />
      </Layout>
      {/* description for search only, hidden from document and screen readers */}
      <p
        data-typesense-field="description"
        style={{ display: `none` }}
        aria-hidden="true"
      >
        From humble beginnings as the William Brooks Shoe Co. in 1932, we are
        now a world leader in quality Outdoor, Work, Western, Public Service and
        Military footwear, but we still call Ohio home.
      </p>
    </>
  )
}

export default RockyBootsPage

export const Head = () => {
  return (
    <>
      <title data-typesense-field="title">Rocky Boots</title>
      <meta
        name="description"
        content="From humble beginnings as the William Brooks Shoe Co. in 1932, we are now a world leader in quality Outdoor, Work, Western, Public Service and Military footwear, but we still call Ohio home."
      ></meta>
    </>
  )
}
