import React from "react"
import PropTypes from "prop-types"
import { Link, graphql, useStaticQuery } from "gatsby"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import * as brandHeaderStyles from "./BrandHeader.module.scss"

const BrandHeader = ({}) => {
  return (
    <div className={brandHeaderStyles.wrapper}>
      <div className={brandHeaderStyles.heroImageWrapper} id="brand-header-hero">
        <StaticImage
          src="../../assets/images/index/brand-headers/rocky/1933-team-banner-mobile.jpg"
          className={brandHeaderStyles.heroImageMB}
          alt="This is Rocky"
        />
        <StaticImage
          src="../../assets/images/index/brand-headers/rocky/1933-team-banner-tablet.jpg"
          className={brandHeaderStyles.heroImageTB}
          alt="This is Rocky"
        />
        <StaticImage
          src="../../assets/images/index/brand-headers/rocky/1933-team-banner-desktop.jpg"
          className={brandHeaderStyles.heroImageDT}
          alt="This is Rocky"
        />
      </div>
      <div className={`brand-container ${brandHeaderStyles.title}`}>
        {/* just a placeholder til dynamic breadcrumb component is built */}
        <h1>This is Rocky</h1>
        <p>Est. 1932 in Southeast Ohio</p>
        <div className={brandHeaderStyles.buttons}>
          <a class="CTAblack" href="https://www.rockyboots.com/Home" title="Rocky Boots">Visit Website</a>
          {/* <a class="CTAalt" href="" title="">Watch Brand Video</a> */}
        </div>
      </div>
    </div>
  )
}

export default BrandHeader
