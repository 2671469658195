import React from "react"
import { Link } from "gatsby"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import * as brandBodyStyles from "./BrandBody.module.scss"

const BrandBody = ({}) => {
  return (
    <div className={brandBodyStyles.brandBody}>
      <div className={brandBodyStyles.about}>
        <div className={brandBodyStyles.info}>
          <h2>About Rocky</h2>
          <p>At Rocky we build rugged, reliable boots for those working, hunting, and farming; and for those serving their country and community.</p>
          <p>90 years ago, we defined our legacy with commitment, loyalty, and tradition. 90 years later, and we're still moving forward.</p>
          <p>This is our heritage. This is Rocky.</p>
        </div>
        <div className={brandBodyStyles.rightImage}>
          <StaticImage
            src="../../assets/images/index/brand-headers/rocky/factory-sepia.jpg"
            className={brandBodyStyles.halfImage}
            alt="Rocky factory 1933"
            imgStyle={{width: `100%`, height: `100%`}}
            style={{width: `100%`, height: `100%`}}
          />
        </div>
      </div>
      <div className={brandBodyStyles.highlightSection}>
        <div className={brandBodyStyles.imageGrid}>
            <StaticImage
                src="../../assets/images/index/brand-body/rocky/rocky-outdoor-square.jpg"
                className={brandBodyStyles.halfImage}
                alt="rocky outdoor"
                imgStyle={{width: `100%`, height: `100%`, padding: '20px 10px 10px 20px'}}
                style={{width: `100%`, height: `100%`}}
              />
              <StaticImage
                src="../../assets/images/index/brand-body/rocky/rocky-work-square.jpg"
                className={brandBodyStyles.halfImage}
                alt="rocky work"
                imgStyle={{width: `100%`, height: `100%`, padding: '20px 20px 10px 10px'}}
                style={{width: `100%`, height: `100%`}}
              />
              <StaticImage
                src="../../assets/images/index/brand-body/rocky/rocky-western-square.jpg"
                className={brandBodyStyles.halfImage}
                alt="rocky western"
                imgStyle={{width: `100%`, height: `100%`, padding: '10px 10px 20px 20px'}}
                style={{width: `100%`, height: `100%`}}
              />
              <StaticImage
                src="../../assets/images/index/brand-body/rocky/rocky-psd-commil-square.jpg"
                className={brandBodyStyles.halfImage}
                alt="rocky public service and commercial military"
                imgStyle={{width: `100%`, height: `100%`, padding: '10px 20px 20px 10px'}}
                style={{width: `100%`, height: `100%`}}
              />
          </div>
          <div className={brandBodyStyles.highlights}>
            <h3>Highlights</h3>
            <h4>Innovation, Quality, and Durability</h4>
            <p>Our footwear is proudly designed in Nelsonville, OH with the best waterproof, outsole, safety toe, and lightweight comfort technologies to offer reliable footwear built to take you anywhere.</p>
            <h4>Legacy 32</h4>
            <p>Every pair of Legacy 32 Outdoor boots is designed to acknowledge the past while bringing you the latest in modern comfort features. A classic silhouette sits on top of a Vibram® outsole, a cushioned EVA midsole, and a fiberglass shank to provide traction and stability on any terrain. A cushioned insole and Rocky VP waterproofing are added to keep your feet comfortable and dry. From there, the uppers come in 6-inch and 8-inch styles with durable and stylish materials like full grain leather, wool, and Waxide canvas.</p>
            <h4>Team Rocky</h4>
            <p>We work with a diverse team of outdoorspeople and adventurers to preserve the heritage &amp; tradition of the great outdoors.</p>
            <h4>Mobilite</h4>
            <p>With the widest oblique toe box in the industry and Rocky® Waterproof construction to keep your feet dry in any conditions, the Mobilite is designed to keep you safe and comfortable.</p>
          </div>
      </div>
      <div className={brandBodyStyles.strategy}>
        <h4>Rocky Boots Strategy</h4>
        <p>Still headquartered in Nelsonville, Ohio, Rocky continues to be a world leader in premium quality Outdoor, Work, Western, Public Service and Military footwear as well as Outdoor and Work apparel and accessories. Innovation, quality, and durability are hallmarks of the Rocky brand.</p>
        <div className={brandBodyStyles.strategyButtons}>
          <Link to="/customer-resources/join-our-family-of-authorized-dealers/" title="Become a Dealer" className="CTAblack">
            Become a Dealer
          </Link>
          <Link to="/customer-resources/for-our-consumers/" title="Customer Resources" className="CTAalt">
            Customer Resources
          </Link>
        </div>
      </div>
    </div>
  )
}

export default BrandBody
